const ELEMENT_ID = '__conciliations'

const loadBootstrap = async () => {
  try {
    const { mount } = await import('./bootstrap')

    const rootElement = document.getElementById(ELEMENT_ID)

    if (!rootElement) {
      const errorMessage = `Element with id ${ELEMENT_ID} was not found.`

      console.error(errorMessage)
      throw new Error(errorMessage)
    }

    mount({ mountPoint: rootElement, strategy: 'browser' })
  } catch (error) {
    console.error('Error loading bootstrap:', error)
  }
}

loadBootstrap()
